/**
 * @generated SignedSource<<1e29ff86c318dcba65ae6ff592042eb7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ContactCardPage_SettingsUI_Query$variables = {
  companyId: string;
};
export type ContactCardPage_SettingsUI_Query$data = {
  readonly company: {
    readonly " $fragmentSpreads": FragmentRefs<"ContactCardPage_company">;
  } | null;
};
export type ContactCardPage_SettingsUI_Query$rawResponse = {
  readonly company: {
    readonly __typename: "Company";
    readonly contactCard: {
      readonly active: boolean;
      readonly companyUrl: {
        readonly value: string;
      };
      readonly displayName: string;
      readonly email: string;
      readonly id: string;
      readonly imageUrl: {
        readonly value: string;
      };
      readonly phones: ReadonlyArray<string>;
      readonly title: string;
    } | null;
    readonly id: string;
  } | {
    readonly __typename: string;
    readonly id: string;
  } | null;
};
export type ContactCardPage_SettingsUI_Query = {
  rawResponse: ContactCardPage_SettingsUI_Query$rawResponse;
  response: ContactCardPage_SettingsUI_Query$data;
  variables: ContactCardPage_SettingsUI_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "companyId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "companyId"
  }
],
v2 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "value",
    "storageKey": null
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ContactCardPage_SettingsUI_Query",
    "selections": [
      {
        "alias": "company",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "ContactCardPage_company"
              }
            ],
            "type": "Company",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ContactCardPage_SettingsUI_Query",
    "selections": [
      {
        "alias": "company",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "CompanyContactCard",
                "kind": "LinkedField",
                "name": "contactCard",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "phones",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "active",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "displayName",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Url",
                    "kind": "LinkedField",
                    "name": "imageUrl",
                    "plural": false,
                    "selections": (v2/*: any*/),
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "title",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Url",
                    "kind": "LinkedField",
                    "name": "companyUrl",
                    "plural": false,
                    "selections": (v2/*: any*/),
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "email",
                    "storageKey": null
                  },
                  (v3/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "type": "Company",
            "abstractKey": null
          },
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "3cdab5a59f1106f8bba9bb21599abdf6",
    "id": null,
    "metadata": {},
    "name": "ContactCardPage_SettingsUI_Query",
    "operationKind": "query",
    "text": "query ContactCardPage_SettingsUI_Query(\n  $companyId: ID!\n) {\n  company: node(id: $companyId) {\n    __typename\n    ... on Company {\n      ...ContactCardPage_company\n    }\n    id\n  }\n}\n\nfragment ContactCardPage_company on Company {\n  contactCard {\n    phones\n    active\n    displayName\n    imageUrl {\n      value\n    }\n    title\n    companyUrl {\n      value\n    }\n    email\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "f20c17545d1e0f57248de78ef4826584";

export default node;
